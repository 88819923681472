import common from "@/common";

let form_options = {}
form_options.company = []

export function reloadFieldData() {
  // init Arrays
  ;[
    form_options.company,
  ].forEach(arr => arr.length = 0)

  common.getCompanyOptions()
    .then(res => {
      form_options.company.push.apply(form_options.company, res)
    })
}

export default [
  {
    name: 'deviceMAC',
    label: 'device_manage.mac',
    type: 'text',
    add_disable: true,
    edit_disable: true,
  },
  {
    name: 'deviceName',
    label: 'geo_fencing.antenna_name',
    type: 'text',
    rule: 'required',
    add_disable: true,
    edit_disable: true,
  },
  {
    name: 'companyID',
    label: 'common.company',
    type: 'select',
    rule: 'required',
    options: form_options.company,
  },
  {
    name: 'facilityID',
    label: 'common.facility',
    type: 'slot',
    rule: 'required',
    slot: 'facility',
  },
]
